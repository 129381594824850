import { create } from "zustand";


export const homeLinks = [
    { label: 'Home', route: route('home') },
    { label: 'Shop', route: route('home.predesign-products', { category: 'all' }) },
    { label: 'Products', route: route('home.product-collections', { collection: 'all' }) },
    { label: 'Templates', route: route('home.template-collections', { collection: 'all' }) },
    { label: 'Designs', route: route('prompt.showcase') },
    { label: 'Contact', route: route('contact-us') },
];

export const steps = [
    { label: "Create/Choose Design", value: 'create-design' },
    { label: "Finalize Your Design", value: 'finalize-design' },
    { label: "Place Your Order", value: 'place-order' },
];

export const useHomeFlow = create((set) => ({
    step: 'create-design',
    product: null, //selected product
    setProduct: (product) => set({ product }),
    setStep: (step) => set({ step }),
    reset: () => set({ step: 'create-design' }),
}));
